import React, { Component } from 'react';

class EditFishForm extends Component {
    handleChange = event => {
        // copy of the current fisg
        const updatedFish = {
            ...this.props.fish,
            [event.currentTarget.name]: event.currentTarget.value
        };
        this.props.updateFish(updatedFish, this.props.index);
    };
    render() {
        const { name, price, status, desc, image } = this.props.fish;
        return (
            <div className="fish-edit">
                <input name="name" type="text" placeholder="Name" onChange={this.handleChange} value={name} />
                <input name="price" type="text" placeholder="Price" onChange={this.handleChange} value={price} />
                <select name="status" onChange={this.handleChange} value={status}>
                    <option value="available">Fresh!</option>
                    <option value="unavailable">Sold out!</option>
                </select>
                <textarea name="desc" placeholder="Desc" onChange={this.handleChange} value={desc}></textarea>
                <input name="image" type="text" placeholder="Image" onChange={this.handleChange} value={image} />
                <button onClick={() => this.props.deleteFish(this.props.index)}>Remove Fish</button>
            </div>
        );
    }
}

export default EditFishForm;