import React, { Component } from 'react'
import Header from './Header'
import Inventory from './Inventory';
import Order from './Order';
import SampleFishes from '../sample-fishes';
import Fish from './Fish';
import base from '../base';

class App extends Component {
    state = {
        fishes: {},
        order: {}
    };

    componentDidMount() {
        const { params } = this.props.match;
        const localStorageRef = localStorage.getItem(params.storeId);
        if (localStorageRef) {
            this.setState({ order: JSON.parse(localStorageRef) });
        }
        this.ref = base.syncState(`${params.storeId}/fishes`, {
            context: this,
            state: 'fishes'
        });
    }

    componentDidUpdate() {
        const { params } = this.props.match;
        localStorage.setItem(params.storeId, JSON.stringify(this.state.order));
    }

    componentWillUnmount() {
        base.removeBinding(this.ref);
    }

    addFish = (fish) => {
        // take a copy of existing state
        const fishes = { ...this.state.fishes };
        // add our new fish
        fishes[`fish${Date.now()}`] = fish;
        // set the new fishes object
        this.setState({
            fishes: fishes
        });
    }

    updateFish = (updatedFish, key) => {
        // take a copy of the current existing fish
        const fishes = { ...this.state.fishes };
        fishes[key] = updatedFish;
        this.setState({ fishes });
    }

    deleteFish = (key) => {
        // take a copy of the current existing fish
        const fishes = { ...this.state.fishes };
        // update the state, remove the selected fish
        fishes[key] = null;
        // update state
        this.setState({ fishes });
    }

    loadSampleFishes = () => {
        this.setState({
            fishes: SampleFishes
        });
    }

    addToOrder = key => {
        const order = { ...this.state.order };
        order[key] = order[key] + 1 || 1;
        this.setState({ order });
    }

    removeFromOrder = key => {
        // copy of the state order
        const order = { ...this.state.order };
        delete order[key];
        this.setState({ order });
    }

    render() {
        return (
            <div className="catch-of-the-day">
                <div className="menu">
                    <Header tagline="Fresh Seafood Market" />
                    <ul className="fishes">
                        {Object.keys(this.state.fishes).map(key => (
                            <Fish key={key} index={key} details={this.state.fishes[key]} addToOrder={this.addToOrder} />
                        ))}
                    </ul>
                </div>
                <Order fishes={this.state.fishes} removeOrder={this.removeFromOrder} order={this.state.order} />
                <Inventory storeId={this.props.match.params.storeId} addFish={this.addFish} updateFish={this.updateFish} deleteFish={this.deleteFish} loadSampleFishes={this.loadSampleFishes} fishes={this.state.fishes} />
            </div>
        )
    }
}

export default App;
